import React from 'react'

import { Box, Typography, styled } from '@mui/material'
import ErrorOutline from '@mui/icons-material/ErrorOutline'

const StyledWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '6rem',
  flexDirection: 'row',
  backgroundColor: theme.palette.error.light,
  width: '50%',
  borderRadius: '5px',
  svg: {
    margin: '1rem',
    color: theme.palette.error.main,
  },
  '& > :not(:first-of-type)': {
    padding: '.8rem',
  },
  '& > *': {
    color: theme.palette.error.dark,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& > :not(:first-of-type)': {
      padding: '.5rem',
    },
  },
}))

const StyledDescription = styled(Typography)({
  fontWeight: 400,
})

const StoreNotFound = () => (
  <StyledWrapper>
    <ErrorOutline />
    <StyledDescription variant="h3">
      There are no Rooms To Go stores of the selected type(s). Please change your State or Store Type filter criteria to
      find stores in your area.
    </StyledDescription>
  </StyledWrapper>
)

export default StoreNotFound
