import React from 'react'

import PropTypes from 'prop-types'
import { styled, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { toID } from '@helpers/store-locator'

const MenuWrapper = styled(FormControl)`
  margin: ${p => p.theme.spacing(4)} 0;

  ${p => p.theme.breakpoints.up('md')} {
    display: none;
  }
`

const MobileStoreSelector = ({ selectedTab, setSelectedTab, data, defaultStoreIndex }) => {
  const onChange = e => setSelectedTab(e.target.value)
  return (
    <MenuWrapper fullWidth>
      <InputLabel id="mobile-store-selector">State</InputLabel>
      <Select
        labelId="mobile-store-selector"
        // TODO: remove when theme get's fixed
        // ! start
        MenuProps={{
          PaperProps: {
            style: { border: 'solid 1px grey' },
          },
        }}
        // ! end
        id="mobile-store-select"
        value={defaultStoreIndex || selectedTab}
        label="State"
        onChange={onChange}
      >
        {data?.map(([state], index) => (
          <MenuItem key={`mobile-${toID(state)}`} value={index}>
            {state}
          </MenuItem>
        ))}
      </Select>
    </MenuWrapper>
  )
}

MobileStoreSelector.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  defaultStoreIndex: PropTypes.number,
}

export default MobileStoreSelector
