import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import { Box, styled } from '@mui/material'

import { toID, groupStoresTypesByAddress, storesTypes } from '@helpers/store-locator'
import StoreMarket from './StoreMarket'
import StoreNotFound from '../StoreNotFound'

// Configure which props should be forwarded on DOM
// ? https://mui.com/system/styled/#custom-components
const panelConfig = {
  shouldForwardProp: prop => prop !== 'shouldShow',
  name: 'Panel',
  slot: 'Root',
}

const Panel = styled(Box, panelConfig)`
  padding: ${p => p.theme.spacing(2)};
  flex: 1;
  display: ${p => (p.shouldShow ? 'block' : 'none')};

  ${p => p.theme.breakpoints.down('md')} {
    padding: 0;
  }
`

const storeTypesToFilter = new Set(['Showroom', 'Kids', 'Patio', 'Outlet'])

const TabPanel = ({ selectedTab, market, filters, index }) => {
  const shouldShow = selectedTab === index
  const shouldHideMarket = ['DOTCOM']

  const filteredMarket = useMemo(
    () =>
      market?.reduce((_markets, [marketName, cities]) => {
        const filteredCities = cities.reduce((_cities, [city, stores]) => {
          const filteredStores = groupStoresTypesByAddress(
            stores.filter(store => {
              const storeType = store.storeType || storesTypes[store.storeTypeId]
              if (storeTypesToFilter.has(storeType)) {
                return (
                  (storeType === 'Showroom' && filters.state.showroom) ||
                  (storeType === 'Kids' && filters.state.kids) ||
                  (storeType === 'Patio' && filters.state.patio) ||
                  (storeType === 'Outlet' && filters.state.outlet)
                )
              }
              return true
            }),
          )
          if (filteredStores.length) _cities.push([city, filteredStores])
          return _cities
        }, [])
        if (filteredCities.length) _markets.push([marketName, filteredCities])
        return _markets
      }, []),
    [filters.state.kids, filters.state.outlet, filters.state.patio, filters.state.showroom, market],
  )

  return (
    <Panel
      role="tabpanel"
      shouldShow={shouldShow}
      id={`storelocator-tabpanel-${index}`}
      aria-labelledby={`storelocator-tab-${index}`}
    >
      {shouldShow &&
        !!filteredMarket?.length &&
        filteredMarket.map(
          ([marketName, cities]) =>
            !shouldHideMarket.includes(marketName) && (
              <StoreMarket filters={filters} key={toID(marketName)} marketName={marketName} cities={cities} />
            ),
        )}
      {!filteredMarket?.length && <StoreNotFound />}
    </Panel>
  )
}

TabPanel.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  market: PropTypes.array.isRequired,
}

export default TabPanel
