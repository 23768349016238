import React from 'react'

import PropTypes from 'prop-types'
import { Box, Typography, styled } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'

import StoreCard from './StoreCard'

const CityHeading = styled(Typography)`
  font-weight: 600;
  border-bottom: solid 1px ${p => p.theme.palette.divider};
  padding-bottom: ${p => p.theme.spacing(1)};
  text-transform: capitalize;
`

const StoreList = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const CityWrapper = styled(motion.div)`
  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(4)};

  ${p => p.theme.breakpoints.down('md')} {
    padding: ${p => p.theme.spacing(2)} 0;
  }
`

const StoreCity = ({ stores, city }) => {
  if (stores.length < 1) return null
  return (
    <AnimatePresence initial={false}>
      <CityWrapper
        layout
        initial={{ opacity: 0, scale: 0.3 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
      >
        <CityHeading variant="body1">{city?.split(',')?.[0]}</CityHeading>
        <StoreList>
          <AnimatePresence initial={false}>
            {stores.map(store => (
              <StoreCard key={`${store.storeNumber}-${store.storeTypeId}`} store={store} />
            ))}
          </AnimatePresence>
        </StoreList>
      </CityWrapper>
    </AnimatePresence>
  )
}

StoreCity.propTypes = {
  city: PropTypes.string.isRequired,
  stores: PropTypes.array.isRequired,
}

export default StoreCity
