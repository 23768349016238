import React, { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { styled, Button } from '@mui/material'
import _uniqBy from 'lodash/uniqBy'

const StyledTypeButton = styled(Button, {
  shouldForwardProp: props => props !== 'trackingData',
})(({ theme }) => ({
  padding: '0 .4rem',
  textTransform: 'capitalize',
  textDecoration: 'underline',
  minWidth: 0,
  lineHeight: 1,
  borderRadius: 0,
  fontWeight: 600,
  height: 'fit-content',

  '&:not(:first-of-type)': {
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
  },
}))

const StoreTypeLabels = ({ types, isFlexPatio, label }) => {
  const [preferredSlug, setPreferredSlug] = useState(null)
  const primarySlug = types?.[0]?.slug
  const hasPatio = types?.some(type => type.name === 'Patio')

  useEffect(() => {
    const storeTypes = types?.reduce((acc, type) => {
      if (!acc[type.name]) {
        acc[type.name] = type.slug
      }
      return acc
    })

    if (storeTypes.Showroom) {
      setPreferredSlug(storeTypes.Showroom)
    } else if (storeTypes.Patio) {
      setPreferredSlug(storeTypes.Patio)
    }
  }, [types])

  const FlexPatioLabel = () => (
    <StyledTypeButton
      variant="text"
      href={`/${primarySlug}`}
      trackingData={{ category: 'store', action: 'click', label }}
    >
      Patio
    </StyledTypeButton>
  )

  return (
    <>
      {types?.map(type => (
        <StyledTypeButton
          key={type.name}
          variant="text"
          href={`/${preferredSlug || type.slug}`}
          trackingData={{ category: 'store', action: 'click', label }}
        >
          {type.name}
        </StyledTypeButton>
      ))}
      {isFlexPatio && !hasPatio && <FlexPatioLabel />}
    </>
  )
}

StoreTypeLabels.propTypes = {
  types: PropTypes.array,
  isFlexPatio: PropTypes.bool,
  label: PropTypes.string,
}

export default StoreTypeLabels
