import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { toID } from '@helpers/store-locator'
import StoreCity from './StoreCity'

const StoreMarket = ({ marketName, cities }) => {
  const [initialComponentState, setInitialComponentState] = useState(Box)

  useLayoutEffect(() => {
    setInitialComponentState(motion.div)
  }, [])

  if (!cities.length) return null

  return (
    <Box
      sx={{ pb: 2 }}
      component={initialComponentState}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        {marketName && `${marketName} AREA`}
      </Typography>
      {cities.map(([city, stores]) => (
        <StoreCity key={toID(city)} city={city} stores={stores} />
      ))}
    </Box>
  )
}

StoreMarket.propTypes = {
  marketName: PropTypes.string.isRequired,
  cities: PropTypes.array.isRequired,
}

export default StoreMarket
