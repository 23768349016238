import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import { Typography, styled, Button } from '@mui/material'
import { motion } from 'framer-motion'
import _uniqBy from 'lodash/uniqBy'

import { formatAddress } from '@helpers/store-locator'
import StoreTypeLabels from './StoreTypeLables'

const StoreWrapper = styled(motion.div)(({ theme }) => ({
  marginRight: theme.spacing(4),
  marginTop: theme.spacing(2),
  width: 360,
  border: `1px solid ${theme.palette.secondary.main}`,
  padding: 16,
  'a:focus': { outline: 'none !important' },

  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: 0,
    marginTop: theme.spacing(1),
  },
}))

const StoreHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  paddingBottom: '0.4rem',
}))

const StoreBodyWrapper = styled('div')({
  marginTop: '0.6rem',
})

const StoreTypesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const StyledPhonesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

const StyledPhoneButton = styled(Button)({
  textTransform: 'capitalize',
  padding: 0,
  minWidth: 0,
  lineHeight: 1,
})

const StyledAddressButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  textTransform: 'capitalize',
  padding: 0,
  minWidth: 0,
  lineHeight: 1,
  p: {
    color: 'unset',
  },
})

const StoreCity = ({ store }) => {
  const phones = useMemo(() => {
    const { types } = store
    const distinctPhones = _uniqBy(types, 'phoneNumber', [])
    if (distinctPhones.length > 1) {
      return types
    }
    return distinctPhones
  }, [store])

  return (
    <StoreWrapper
      key={`${store.storeNumber}-${store.storeTypeId}`}
      layout
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
    >
      <StoreHeaderWrapper>
        {`${store.name?.trim()}: `}
        <StoreTypesWrapper>
          <StoreTypeLabels types={store?.types} isFlexPatio={store?.isFlexPatio} label={store?.label} />
        </StoreTypesWrapper>
      </StoreHeaderWrapper>
      <StoreBodyWrapper>
        <StyledAddressButton variant="text" href={`/${store.storeSlug}`}>
          <Typography>{formatAddress(store.address1)}</Typography>
          <Typography sx={{ textTransform: 'capitalize' }}>
            {store.city}, {store.state} {store.zipcode}
          </Typography>
        </StyledAddressButton>
        <StyledPhonesWrapper>
          {phones.map(type => (
            <Typography key={type.name}>
              <StyledPhoneButton variant="text" href={`tel:${type.phoneNumber}`}>
                {`${type.phoneNumber} ${phones.length > 1 ? `(${type.name})` : ''}`}
              </StyledPhoneButton>
            </Typography>
          ))}
        </StyledPhonesWrapper>
      </StoreBodyWrapper>
    </StoreWrapper>
  )
}

StoreCity.propTypes = {
  store: PropTypes.object.isRequired,
}

export default StoreCity
