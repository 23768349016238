import React from 'react'
import { bool, string } from 'prop-types'
import { Badge, styled } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Grid from '@mui/material/Grid'
import { colors, fonts } from '@constants/styles'

const StyledDiv = styled('div')(({ isMobile }) => ({
  outline: `${colors.red} solid 1px`,
  padding: '25px',
  margin: isMobile ? '20px' : '0 20px 20px',
  ...(!isMobile && { maxWidth: '400px' }),
}))

const StyledSpan = styled('span')({
  color: colors.red,
  fontSize: fonts.txtMedium,
  fontWeight: `bold`,
  display: `inline-block`,
})

const WarningMessage = ({ className, isMobile }) => (
  <StyledDiv className={className} isMobile={isMobile}>
    <Grid container spacing={0.5}>
      <Grid item xs={1}>
        <span>
          <Badge overlap="circular" badgeContent={<ErrorOutlineIcon color="error" />} />
        </span>
      </Grid>
      <Grid item xs={11}>
        <StyledSpan>
          There are no Rooms To Go stores within 100 miles of your search. Please change your ZIP code or filter
          criteria to find stores nearest you.
        </StyledSpan>
      </Grid>
    </Grid>
  </StyledDiv>
)

WarningMessage.propTypes = {
  className: string,
  isMobile: bool,
}

export default WarningMessage
